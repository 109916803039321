import {
  Button,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Select,
  Table,
  TableColumnsType,
} from "antd";
import { useEffect, useState } from "react";
import f from "../../components/f";
import { PlusOutlined } from "@ant-design/icons";

export default function UserSupport() {
  const [data, setData] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [editId, setEditId] = useState<string | null>(null);
  const [editData, setEditData] = useState<{
    response: string;
  }>({
    response: "",
  });
  const [page, setPage] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [messageApi, contextHolder] = message.useMessage();

  const save = async (id: string) => {
    const save = await f(
      `/help/user-support/${id}`,
      { response: editData.response },
      "PUT"
    );
    if (!save.success) return messageApi.error(save.error);
    fetchTickets(page.page, page.pageSize);
    messageApi.success("Responded successfully");
    setEditId(null);
  };

  const columns: TableColumnsType = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "14%",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      width: "14%",
      render: (text: string, record: any) => {
        return (
          <div>
            {record.contact.split("\n").map((item: string) => {
              if (item.includes("@")) {
                return (
                  <a
                    href={`mailto:${item}`}
                    className="text-indigo-500 hover:!text-indigo-700"
                  >
                    {item}
                    {"\n"}
                  </a>
                );
              } else {
                return (
                  <a
                    href={`tel:${item}`}
                    className="text-indigo-500 hover:!text-indigo-700"
                  >
                    {item}
                  </a>
                );
              }
            })}
          </div>
        );
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      width: "20%",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "26%",
      render: (text: string, record: any) => {
        return (
          <>
            {text}
            <br />
            <b>
              {new Date(record.createdAt).toLocaleDateString("default", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "numeric",
                hour12: true,
              })}
            </b>
          </>
        );
      },
    },
    {
      title: "Response",
      dataIndex: "response",
      key: "response",
      width: "20%",
      render: (text: string, record: any) => {
        if (editId === record.id) {
          return (
            <Input
              value={editData.response}
              onChange={(e) => {
                setEditData({ ...editData, response: e.target.value });
              }}
            />
          );
        }
        return (
          <>
            {text ? (
              <>
                {text}
                <br />
                <b>{record.respondedBy}</b>
                <br />
                <b>
                  {new Date(record.updatedAt).toLocaleDateString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "numeric",
                    hour12: true,
                  })}
                </b>
              </>
            ) : (
              <b className="text-red-500">Not responded</b>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "6%",
      render: (text: string, record: any) => (
        <div>
          {editId === record.id ? (
            <>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => save(record.id)}
              >
                <a className="text-indigo-500 hover:!text-indigo-700">Save</a>
              </Popconfirm>
              <Button type="link" onClick={() => setEditId(null)} danger>
                Cancel
              </Button>
            </>
          ) : (
            <Button
              type="link"
              onClick={() => {
                setEditId(record.id);
                setEditData({ ...record });
              }}
            >
              Respond
            </Button>
          )}
        </div>
      ),
    },
  ];

  const fetchTickets = async (page: number, pageSize: number) => {
    const r = await f("/help/user-support", { page, pageSize }, "GET");
    if (!r.success) return messageApi.error(r.error);
    setData(r.data.tickets);
    setTableCount(r.data.totalCount);
  };

  useEffect(() => {
    fetchTickets(page.page, page.pageSize);
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold text-indigo-500 p-4">
          User Support
        </h1>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          total: tableCount,
          showSizeChanger: true,
          defaultPageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page, pageSize) => {
            setPage({ page, pageSize });
            fetchTickets(page, pageSize);
          },
        }}
      />
      {contextHolder}
    </div>
  );
}
