import {
  Button,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Select,
  Table,
  TableColumnsType,
} from "antd";
import { useEffect, useState } from "react";
import f from "../../components/f";
import { PlusOutlined } from "@ant-design/icons";

export default function Members() {
  const [data, setData] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [editId, setEditId] = useState<string | null>(null);
  const [editData, setEditData] = useState<{
    umoor: string;
    coordinator: string;
    phone: string;
    note: string;
  }>({
    umoor: "",
    coordinator: "",
    phone: "",
    note: "",
  });
  const [page, setPage] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });
  const [messageApi, contextHolder] = message.useMessage();
  const del = async (id: string) => {
    const del = await f(`/umoor/members/${id}`, {}, "DELETE");
    if (!del.success) return messageApi.error(del.error);
    fetchMembers(page.page, page.pageSize);
    messageApi.success("Deleted successfully");
  };
  const save = async (id: string) => {
    const save = await f(
      `/umoor/members/${id}`,
      {
        umoor: editData.umoor,
        coordinator: editData.coordinator,
        phone: editData.phone,
        note: editData.note,
      },
      "PUT"
    );
    if (!save.success) return messageApi.error(save.error);
    fetchMembers(page.page, page.pageSize);
    messageApi.success("Saved successfully");
    setEditId(null);
  };

  const columns: TableColumnsType = [
    {
      title: "Umoor",
      dataIndex: "umoor",
      key: "umoor",
      width: "25%",
      render: (text: string, record: any) =>
        editId === record.id ? (
          <Input
            value={editData.umoor}
            onChange={(e) => {
              setEditData({ ...editData, umoor: e.target.value });
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Coordinator",
      dataIndex: "coordinator",
      key: "coordinator",
      width: "25%",
      render: (text: string, record: any) =>
        editId === record.id ? (
          <Input
            value={editData.coordinator}
            onChange={(e) => {
              setEditData({ ...editData, coordinator: e.target.value });
            }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "15%",
      render: (text: string, record: any) =>
        editId === record.id ? (
          <Input
            value={editData.phone}
            onChange={(e) => {
              setEditData({ ...editData, phone: e.target.value });
            }}
          />
        ) : (
          <a className="underline" href={`tel:${text}`}>
            {text}
          </a>
        ),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "20%",
      render: (text: string, record: any) =>
        editId === record.id ? (
          <Input
            value={editData.note}
            onChange={(e) => {
              setEditData({ ...editData, note: e.target.value });
            }}
          />
        ) : (
          text
        ),
    },
    {
      width: "15%",
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <div>
          {editId === record.id ? (
            <>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => save(record.id)}
              >
                <a className="text-indigo-500 hover:!text-indigo-700">Save</a>
              </Popconfirm>
              <Button type="link" onClick={() => setEditId(null)} danger>
                Cancel
              </Button>
            </>
          ) : (
            <div>
              <Button
                type="link"
                onClick={() => {
                  setEditId(record.id);
                  setEditData({ ...record });
                }}
              >
                Edit
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => {
                  del(record.id);
                }}
              >
                <a className="text-rose-500 hover:!text-rose-600">Delete</a>
              </Popconfirm>
            </div>
          )}
        </div>
      ),
    },
  ];

  const fetchMembers = async (page: number, pageSize: number) => {
    const r = await f("/umoor/members", { page, pageSize }, "GET");
    if (!r.success) return messageApi.error(r.error);
    setData(r.data.members);
    setTableCount(r.data.totalCount);
  };

  useEffect(() => {
    fetchMembers(page.page, page.pageSize);
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold text-indigo-500 p-4">Umoor</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="m-4"
          onClick={() => {
            setEditId("new");
            setEditData({
              umoor: "",
              coordinator: "",
              phone: "",
              note: "",
            });
          }}
        >
          Add Member
        </Button>
      </div>
      {editId === "new" && (
        <div className="flex flex-row gap-2 p-4">
          <Input
            placeholder="Umoor"
            value={editData.umoor}
            onChange={(e) => {
              setEditData({ ...editData, umoor: e.target.value });
            }}
          />
          <Input
            placeholder="Coordinator"
            value={editData.coordinator}
            onChange={(e) => {
              setEditData({ ...editData, coordinator: e.target.value });
            }}
          />
          <Input
            placeholder="Phone"
            value={editData.phone}
            onChange={(e) => {
              setEditData({ ...editData, phone: e.target.value });
            }}
          />
          <Input
            placeholder="Note"
            value={editData.note}
            onChange={(e) => {
              setEditData({ ...editData, note: e.target.value });
            }}
          />

          <Button
            type="primary"
            onClick={async () => {
              const create = await f(
                "/umoor/members",
                {
                  umoor: editData.umoor,
                  coordinator: editData.coordinator,
                  phone: editData.phone,
                  note: editData.note,
                },
                "POST"
              );
              if (!create.success) return messageApi.error(create.error);
              fetchMembers(page.page, page.pageSize);
              messageApi.success("Member added successfully");
              setEditId(null);
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditId(null);
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          total: tableCount,
          showSizeChanger: true,
          defaultPageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page, pageSize) => {
            setPage({ page, pageSize });
            fetchMembers(page, pageSize);
          },
        }}
      />
      {contextHolder}
    </div>
  );
}
